<template lang="html">
  <v-form ref="form" lazy-validation autocomplete="off">
    <v-card class="elevation-1">
      <v-card-title>
        {{ $tc('label.cadastro', 1) }} :: {{ $tc('label.projeto', 1)}}
      </v-card-title>
      <v-container grid-list-xl fluid>
        <metadados-container-layout
          :layout-class="layoutClass"
          :input-layout="inputLayout"
          :objeto="projeto"
          :formulario-filtros="false"
          :somenteLeitura="somenteLeitura"
          :novoCadastro="true"
          @MetadadosContainerLayout_abrirCadastro="abrirCadastro"
          ref="container">
        <v-col cols="12" sm="12" md="6" slot="antes">
          <v-autocomplete
            id="planejamento-autocomplete-cliente"
            class="custom-autocomplete"
            v-model="projeto.instituicao"
            return-object
            :items="clientes"
            :label="`${$tc('label.instituicao', 1)} *`"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            item-text="codNome"
            item-value="id"
            :rules="[rules.required]"
            required
            :disabled="instituicaoEditavel"
            :clearable="true"
            :search-input.sync="buscaListaClientes"
            @click.native="buscaClientes"
            >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="12" md="6" slot="antes">
            <v-textarea
              id="campo_nome"
              name="campo_nome"
              v-model="projeto.nomProjeto"
              :label="`${$tc('label.nom_projeto', 1)} *`"
              :rules="[rules.required]"
              required
              :disabled="somenteLeitura"
              :counter="200"
              maxlength="200"
              auto-grow
              rows="1"
            >
            </v-textarea>
          </v-col>
          <v-col cols="12" sm="12" md="12" slot="antes">
            <v-textarea
              id="campo_nome"
              name="campo_nome"
              v-model="projeto.objetivoProjeto"
              :label="`${$tc('label.objetivo_projeto', 1)} *`"
              :rules="[rules.required]"
              required
              :disabled="somenteLeitura"
              :counter="1000"
              maxlength="1000"
              auto-grow
              rows="1" >
            </v-textarea>
          </v-col>
          <v-col cols="12" sm="4" md="3" slot="antes" >
            <v-select
              id="tamanho_projeto"
              name="tamanho_projeto"
              :items="tamanhoProjetos"
              :label="`${$tc('label.tamanho_projeto', 1)} *`"
              v-model="projeto.tamanho"
              item-text="texto"
              item-value="valor"
              chips
              :disabled="somenteLeitura"
              deletable-chip
              clearable
              :rules="[rules.required]"
              required
              >
            </v-select>
         </v-col>
         <v-col cols="6" sm="4" md="3" slot="antes">
            <v-text-field
               class="ProjetoForm__input"
              id="dat_inicio"
              name="dta_inicio"
              v-model="projeto.dtaInicio"
              :label="`${$tc('label.data_inicio', 1)}`"
              :disabled="true"
              >
            </v-text-field>
          </v-col>
           <v-col cols="6" sm="4" md="3" slot="antes">
            <v-text-field
              class="ProjetoForm__input"
              id="dta_fim"
              name="dta_fim"
              v-model="projeto.dtaFim"
              :label="`${$tc('label.data_fim', 1)}`"
              :disabled="true"
              >
            </v-text-field>
          </v-col>
           <v-col cols="12" sm="4" md="3" slot="antes" >
            <v-switch
              color="primary"
              :label="projeto.indAtivo ? $tc('label.ativo',1) : $tc('label.inativo',1) "
              v-model="projeto.indAtivo"
              :disabled="somenteLeitura"
              class="mt-3">
            </v-switch>
          </v-col>
           <v-col cols="6" sm="4" md="3" slot="antes">
            <v-text-field
              id="vlr_investido"
              name="vlr_investido"
               :value="getMoney(projeto.vlrReservado + projeto.vlrConsumido)"
              :label="`${$tc('label.vlr_investido', 1)}`"
              :disabled="true"
              >
            </v-text-field>
          </v-col>
          <v-col cols="6" sm="4" md="3" slot="antes">
            <v-text-field
              id="vlr_comprometido"
              name="vlr_comprometido"
              :value="getMoney(projeto.vlrReservado)"
              :label="`${$tc('label.vlr_comprometido', 1)}`"
              :disabled="true"
              >
            </v-text-field>
          </v-col>
          <v-col cols="6" sm="4" md="3" slot="antes">
            <v-text-field
              id="vlr_consumido"
              name="vlr_consumido"
              :value="getMoney(projeto.vlrConsumido)"
              :label="`${$tc('label.vlr_consumido', 1)}`"
              :disabled="true"
              >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="3" slot="antes">
            <v-textarea
              id="campo_nome"
              name="campo_nome"
              v-model="projeto.reportGlobal"
              :label="`${$tc('label.report_global', 1)}`"
              :disabled="somenteLeitura"
              :counter="200"
              maxlength="200"
              auto-grow
              rows="1"
              >
            </v-textarea>
          </v-col>
          <v-col cols="12" sm="12" md="12" slot="antes">
            <v-textarea
              id="campo_nome"
              name="campo_nome"
              v-model="projeto.observacao"
              :label="`${$tc('label.observacao', 1)}`"
              :disabled="somenteLeitura"
              :counter="1000"
              maxlength="1000"
              auto-grow
              rows="1"
              >
            </v-textarea>
          </v-col>
        </metadados-container-layout>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn id="btn_cancelar_novo_projeto" @click.native="openCancelarDialog">{{ somenteLeitura ?  $t('label.voltar') :  $t('label.cancelar') }}</v-btn>
        <v-btn id="btn_salvar_novo_projeto" v-if="!somenteLeitura" @click.native="openSaveDialog" color="primary">{{ $t('label.salvar') }}</v-btn>
      </v-card-actions>
    </v-card>

    <confirm
      ref="confirmDialog"
      :message="$t('message.deseja_salvar', { text: this.$tc('label.projeto', 1).toLowerCase() })"
      @agree="salvar">
    </confirm>

    <confirm
      ref="cancelarDialog" :title="$t('title.atencao')"
      :message="$t('message.tem_certeza_cancelar')"
      @agree="cancelar">
    </confirm>
  </v-form>
</template>
<script>

import MetadadosContainerLayout from '../../produto/shared-components/metadados/MetadadosContainerLayout';
import Confirm from '../../produto/shared-components/vuetify/dialog/Confirm';
import { buscarClientes } from '../../common/resources/akzo-acao-projeto';
import { getMoney } from '../../produto/common/functions/helpers';
import { formatDatePattern } from '../../produto/common/functions/date-utils';
import { skipLoading } from '../../produto/common/functions/loading';

export default {
  name: 'ProjetoForm',
  props: {
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MetadadosContainerLayout,
    Confirm,
  },
  data() {
    return {

      resourceProjeto: this.$api.akzoAcaoProjeto(this.$resource),

      projeto: {
        instituicao: null,
        nomProjeto: null,
        tamanho: null,
        dtaInicio: this.formatDatePattern(new Date()),
        dtaFim: this.formatDatePattern(new Date()),
        indAtivo: true,
        vlrInvestido: 0,
        vlrReservado: 0,
        vlrConsumido: 0,
        reportGlobal: null,
        observacao: null,
        objetivoProjeto: null,
      },
      buscaListaClientes: null,
      clientes: [],

      tamanhoProjetos: [
        {
          texto: this.$t('label.tamanho_projeto_p'),
          valor: this.$t('label.tamanho_projeto_p'),
        },
        {
          texto: this.$t('label.tamanho_projeto_m'),
          valor: this.$t('label.tamanho_projeto_m'),
        },
        {
          texto: this.$t('label.tamanho_projeto_g'),
          valor: this.$t('label.tamanho_projeto_g'),
        },
      ],

      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: false,
        md4: true,
        md6: false,
        md9: false,
      },
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  computed: {
    instituicaoEditavel() {
      return this.somenteLeitura || !!this.projeto.id;
    },
  },
  watch: {
    buscaListaClientes(val) {
      const { instituicao } = this.projeto;
      if (instituicao && instituicao.codNome === val) {
        return;
      }
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val) this.buscaClientes(val);
      }, 500);
    },
  },
  methods: {
    getMoney,
    formatDatePattern,
    buscaClientes(autocomplete) {
      const parametros = {
        autocomplete,
        id: this.projeto.idCliente,
      };

      buscarClientes(parametros, this.$resource)
        .then((res) => {
          this.clientes = res.data;
          if (this.projeto.id) {
            // eslint-disable-next-line prefer-destructuring
            this.projeto.instituicao = this.clientes[0];
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    openCancelarDialog() {
      if (this.somenteLeitura) {
        this.cancelar();
        return;
      }
      this.$refs.cancelarDialog.open();
    },
    openSaveDialog() {
      if (!this.$refs.form.validate()) return;
      this.$refs.confirmDialog.open();
    },
    salvar() {
      const objArmazenamento = {
        ...this.projeto,
        ...this.$refs.container.getValoresDependencias(),
      };
      if (this.projeto.id) {
        this.atualizar(objArmazenamento);
      } else {
        this.gravar(objArmazenamento);
      }
    },
    gravar(obj) {
      obj.cnpjCliente = obj.instituicao.desCnpj;
      obj.idCliente = obj.instituicao.id;
      this.resourceProjeto.gravar(obj)
        .then(() => {
          this.$toast(this.$t('message.adicionado_confira_tabela'));
          this.projeto = {};
          this.$router.push({ name: this.$route.params.from || 'projeto' });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    atualizar(obj) {
      obj.cnpjCliente = obj.instituicao.desCnpj;
      obj.idCliente = obj.instituicao.id;
      this.resourceProjeto.atualizar(obj)
        .then(() => {
          this.$toast(this.$t('message.atualizado_com_sucesso'));
          this.clienteIndireto = {};
          this.$router.push({ name: this.$route.params.from || 'projeto' });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    cancelar() {
      this.$router.push({ name: this.$route.params.from || 'projeto' });
    },
    abrirCadastro(metadados) {
      this.novaExtensao = {};
      this.novaExtensao.indAtivo = true;
      this.novaExtensao.idNivelExtensao = metadados.idNivelExtensao;
      this.novaExtensao.metadados = metadados;
      this.dialogCadastroExtensao = true;
    },
    carregarProjeto() {
      const { id } = this.$route.params;
      skipLoading();
      this.resourceProjeto.buscar({ id })
        .then((res) => {
          this.projeto = { ...this.projeto, ...res.body };
          if (this.projeto.idCliente) {
            this.buscaClientes();
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
  },
  beforeMount() {
    if (this.$route.params.id) {
      this.carregarProjeto();
    }
  },
};
</script>

<style>
   .ProjetoForm__input .v-text-field__slot input {
      margin-top: 10px !important;
   }
</style>
